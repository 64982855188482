// src/components/MainPage.js
import React from 'react';
import HeroSection from './HeroSection';
import About from './About';
import Footer from './Footer';

function MainPage() {
  return (
    <div>
      <HeroSection />
      <About />
      <Footer />
    </div>
  );
}

export default MainPage;