import React from 'react';
import Teams from './Teams';
import Footer from './Footer';


function Teampage() {
  return (
    <div>
      <Teams />
      <Footer />
    </div>
  );
}

export default Teampage;