import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css'; // Ensure Tailwind CSS is imported
import Main from './components/Main';
import Formpage from './components/Formpage';
import Teampage from './components/Teampage';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/contact" element={<Formpage />} />
          <Route path="/team" element={<Teampage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;