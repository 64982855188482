import React from 'react';
import Form from './Form';
import Footer from './Footer';


function Formpage() {
  return (
    <div>
      <Form />
      <Footer />
    </div>
  );
}

export default Formpage;